import { Type } from "../types/moduleTypes"

export interface Assessment {
    id: string
    lessonId?: string
    lessonNumber?: number
    module: string
    number?: number
    title?: string
    type: Type
}

export const AssessmentViewOptions = ["Grade", "Correct/Incorrect", "Answers"] as const
export type AssessmentViewOptionsType = typeof AssessmentViewOptions[number]

export const AssessmentGradeOptions = ["correct", "incorrect"] as const
export type AssessmentGradeOptionsType = typeof AssessmentGradeOptions[number]

export const assessmentViewDescriptions = {
    "Grade": "Students can only see the multiple choice grade",
    "Correct/Incorrect": "Students can see the multiple choice grade and if their answers are correct",
    "Answers": "Students can see the multiple choice grade and the correct answer for each question"
}
