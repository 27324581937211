import { NavLink } from "react-router-dom"
import styles from "./sidebar.module.scss"
import { useContext } from "react"
import { SidebarContext } from "./SidebarContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

export interface SidebarLink {
    title: string
    path: string
    icon?: IconProp
}

interface Props {
    links: SidebarLink[]
}

export default function SidebarLinks({ links }: Props) {
    const { collapsed, toggleCollapse } = useContext(SidebarContext) // collapse sidebar when a sidebar link is clicked on Only If it's mobile screen

    return (
        <ul className={styles.sidebarLinks}>
            {links.map((link) => (
                <NavLink to={link.path} key={link.title + link.path} onClick={window.innerWidth <= 430 && toggleCollapse}>
                    {
                        ({ isActive }) => 
                            <li className={`${isActive ? styles.active : ""} ${collapsed ? styles.collapsedLink : ""}`}>
                                {link.icon && <FontAwesomeIcon icon={link.icon} className={collapsed ? "" : "me-2"} />}
                                {collapsed ? "" : link.title}
                            </li>
                    }
                </NavLink>
            ))}
        </ul>
    )
}
