/**
 * Get a Topic's data
 */
import axios from "axios"
import { CurriculumTopic } from "shared/types/curriculumTypes"

export type TopicResponse = {
    module: string,
    type: string,
    number: number,
    lessonNumber: number,
    title: string,
    id: string,
    lessonId: string,
}

export function findById({ id }: { id: string }): Promise<CurriculumTopic> {
    return axios.get(`/api/curriculum/topic?topicId=${id}`).then((res) => {
        return res.data
    })
}

const topics = {
    findById,
}

export default topics
