import * as React from "react"
import { Modal } from "react-bootstrap"
import * as teacher from "../../../../shared/routes/teacher"
import ClassForm from "./ClassForm"
import styles from "../teacher.module.scss"
import { toast } from "react-toastify"
import { ClassResponse } from "shared/types/teacherTypes"
import { useNavigate } from "react-router-dom"

interface Props {
    show: boolean
    onHide: () => void
    activeClass?: ClassResponse
    classCreated: (
        //Return type of teacher.createClass after promises is awaited
        response: Awaited<ReturnType<typeof teacher.createClass>>
    ) => void
}

export default function AddClass({activeClass = null, show, onHide, classCreated}: Props) {
    const navigate = useNavigate()
    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header className="border-0" closeButton />
            <Modal.Body className="mx-5 text-start">
                <h1 className="h3">Create Class</h1>
                <p className={styles.greyText}>Please enter your class's information</p>
                <ClassForm
                    initialValues= {{
                            className: "",
                            academicYear: activeClass?.academicYear ?? new Date().getFullYear(),
                            term: activeClass?.term ?? null,
                            gradeLevel: activeClass?.gradeLevel ?? null,
                            requireCompletion: activeClass?.requireCompletion,
                            standardId: activeClass?.standardId ?? null,
                            studentAssessmentViewOption: "Grade"
                        }}
                    classFormType="Add Class"
                    onSubmit={(values) => {
                        return teacher.createClass({...values, academicYear: values.academicYear || null})
                            .then((response) => {
                                classCreated(response)
                                toast.success("A class has been added.")
                                navigate(`/dashboard/teacher/overview`)
                            })
                            .catch(() => {
                                //TODO: (STEM-521) Could use some client side error reporting
                                toast.error("There has been an error creating your class. Please try again.")
                            })
                    }}
                />
            </Modal.Body>
        </Modal>
    )
}
