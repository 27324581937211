import Pane from "components/Dashboards/General/Pane"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useNavigate } from "react-router"
import styles from "./editingStyles.module.scss"
import FAIcon from "components/General/FAIcon"
import { useState } from "react"
import titleCase from "helpers/titleCase"

const { selectable } = styles

interface Props {
    items: Array<{
        id: string
        name?: string
        header?: string
    }>
    itemType: string
    createItem: () => Promise<any>
    updateItem: (values: { id: string, order: number }) => Promise<any>
    deleteItem: (values: { id: string }) => Promise<any>
}

export default function EditingListPane({ itemType, items, createItem, updateItem, deleteItem }: Props) {
    const navigate = useNavigate()

    const [modal, setModal] = useState(null)
    const [deleteModalState, setDeleteModalState] = useState<{ id: string, order: number, name: string }>({ id: null, order: null, name: null })

    return <>
        {items.map((item, index) => (
            <Row className="my-2" key={item.id}>
                <Col onClick={() => navigate(`${itemType}/${item.id}`)}>
                    <Pane className={selectable}><div>{item.name ?? item.header}</div></Pane>
                </Col>

                            <Col xs="auto">
                                <Button disabled={index === 0} onClick={async () => {
                                    updateItem({
                                        id: item.id,
                                        order: index
                                    })
                                }}><FAIcon iconName={"sort-up"} /></Button>
                            </Col>

                            <Col xs="auto">
                                <Button disabled={index === items.length - 1} onClick={async () => {
                                    updateItem({
                                        id: item.id,
                                        order: index + 2
                                    })
                                }}
                                ><FAIcon iconName={"sort-down"} /></Button>
                            </Col>

                            <Col xs="auto" onClick={() => {
                                setModal("deleteItem")
                                setDeleteModalState({
                                    id: item.id,
                                    name: item.name ?? item.header,
                                    order: index + 1,
                                })
                            }}>
                                <Button variant="danger"><FAIcon iconName={"skull"} /></Button>
                            </Col>
            </Row>
        ))}

        <Row>
            <Col>
                <Button onClick={createItem}><FAIcon iconName="plus" /></Button>
            </Col>
        </Row>

        <Modal show={modal === "deleteItem"} onHide={() => {
            setModal(null)
            setDeleteModalState({
                id: null,
                name: null,
                order: null
            })
        }}>
            <Modal.Header closeButton>
                Delete {titleCase(itemType)}
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <h3>Are you sure you wish to delete {titleCase(itemType)} #{deleteModalState.order}, {deleteModalState.name}?</h3>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={async () => {
                    await deleteItem({ id: deleteModalState.id })
                    setDeleteModalState({
                        id: null,
                        name: null,
                        order: null
                    })
                    setModal(null)
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>
}
