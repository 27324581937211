import React, { ReactElement, useState } from "react"
import { toast } from "react-toastify"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import modules from "shared/routes/moduleRoutes"
import { StudentSubmission, LessonSubmissionResponse, PublishGradeProps, AssessmentSubmissionResponse, TopicSubmissionResponse } from "shared/types//moduleTypes"
import fillTableData from "./helpers/fillTableData"
import Pane from "components/Dashboards/General/Pane"
import { Button, Col, Row } from "react-bootstrap"
import { TeacherGradesState } from "components/Lessons/Grading/GradeList/TeacherGrades"
import styles from "./submissionTeacher.module.scss"
import { LessonResponse } from "shared/lessons"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import { TeacherAssignmentResponse } from "../../../../../shared/types/teacherTypes"
import TeacherAssessmentResponses from "./TeacherResponses/TeacherAssessmentResponses"
import LessonResponses from "./TeacherResponses/TeacherLessonResponses"
import TopicResponses from "./TeacherResponses/TeacherTopicResponses"
import TableResponses from "./TeacherResponses/TeacherTableResponses"
import LessonSidebar from "./TeacherResponses/TeacherLessonSidebar"
import { formatGrade } from "shared/helpers/formatGrade"
import useBoolean from "helpers/useBoolean"

/**
 * Location state properties that this page is expecting when being redirected here
 */
export class SubmissionTeacherState {
    constructor() {
        this.previousPage = ".."
        this.lesson = null
    }
    submissions: StudentSubmission[]
    assignment: TeacherAssignmentResponse
    currentIndex: number
    previousPage?: string
    lesson?: LessonResponse
}

interface Props {
    //Not defined for LTI classes
    activeClass?: {
        standardId: string
    }
}

export default function SubmissionTeacher({ activeClass }: Props) {
    const location = useLocation()
    const state = location.state as SubmissionTeacherState
    const [currentIndex, setCurrentIndex] = React.useState<number>(state?.currentIndex)

    const { lesson, topic } = state.assignment

    const currentSubmission = state.submissions[currentIndex]
    const { studentId } = currentSubmission
    const { studentTopicId, studentLessonId, id: submissionId } = currentSubmission.submission
    const totalSubmissions = state.submissions.length

    const [lessonSubmission, setLessonSubmission] = React.useState<LessonSubmissionResponse>(null)
    const [topicSubmission, setTopicSubmission] = React.useState<TopicSubmissionResponse>(null)
    const [assessmentSubmission, setAssessmentSubmission] = React.useState<AssessmentSubmissionResponse>(null)
    const [selectedStandards, setSelectedStandards] = React.useState<PublishGradeProps["standardsMastery"]>([])

    const [expand, setExpand] = useState(true)

    const sumPoints = (lessonSummary: LessonSubmissionResponse) => lessonSummary.responses?.map(r => r.grade ?? 0).reduce((a, i) => a + i) + (lessonSummary.tableGrade ?? 0)

    const [refresh, toggleRefresh] = useBoolean(false) // to refresh API call when assessment drawing question is graded

    React.useEffect(() => {
        if (topic) {
            modules.getTopicSubmission({ studentTopicId: studentTopicId})
                .then(data => {
                    setTopicSubmission(data)
                })
        }
        if (lesson && studentLessonId) {
            modules.getLessonSubmission({ moduleName: lesson.module, lessonNumber: lesson.number, lessonType: lesson.type, studentLessonId })
                .then((data) => {
                    data.grade = data.grade || (data.points / data.totalPoints * 100)

                    setLessonSubmission({
                        ...data
                    })
                    fillTableData()
                    setSelectedStandards([
                        ...selectedStandards,
                        ...data.standardsMastery
                    ])
                }).catch((err) => {
                    toast.error(err?.response?.data?.message || "Could not retrieve submission")
                })
        } else if (state.assignment.lessonVariety === "Pure Inquiry") {
            pureInquiry.getFull({ pureInquiryId: studentLessonId }).then(({ data }) => {
                data.grade = data.grade || data.points / data.totalPoints * 100
                setLessonSubmission({
                    ...data
                })
                //TODO: use other table setup
            })
        }
        if (state.assignment.assessmentId) {
            modules.getAssessmentSubmission({ assessmentId: state.assignment.assessmentId, studentId: studentId, submissionId: submissionId }).then((assessmentSubmissions) => {
                setAssessmentSubmission({
                    ...assessmentSubmissions
                })
            }).catch((err) => {
                toast.error(err?.response?.data?.message)
                setAssessmentSubmission(null)
            })
        }
    },
        //Justification: This hook should not run every time selectedStandards is changed
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [lesson, topic, studentLessonId, submissionId, refresh])

    // needed for navigating back a page
    const TeacherGradesState: TeacherGradesState = {
        assignment: state.assignment,
        lesson: state.lesson
    }

    const studentName = topicSubmission ? topicSubmission?.studentName : lessonSubmission ? lessonSubmission?.studentName : assessmentSubmission ? assessmentSubmission?.studentName : null
    const assessmentOnly = !state.assignment?.lessonId && state.assignment?.assessmentId // no lesson, but has assessment

    //  Assessment Components
    function displayAssessmentSidebar(): ReactElement {
        if (assessmentSubmission === null) return <></>
        return <>
            <div>
                <strong>Assessment Grade: </strong>{formatGrade((assessmentSubmission.grade ?? 0).toString())}
            </div>
        </>
    }

    return <>
        <Row className="sticky-top bg-white pt-3">
            <Col sm={5} md={6} lg={5}>
                <div className="text-start mb-3">
                    <Link to={state.previousPage ?? ".."} state={TeacherGradesState} className="text-decoration-none">
                        <i className="fas fa-arrow-left" />
                        <span> Return to Submissions</span>
                    </Link>
                </div>
            </Col>
            <Col sm={7} md={6} lg={4}>
                <div className={`${styles.submissionPagination} float-end`}>
                    <Button
                        variant="theme"
                        disabled={currentIndex === 0}
                        className={`btn btn-default ${styles.wideButton}`}
                        onClick={() => {
                            setCurrentIndex(currentIndex - 1)
                        }}
                    >
                        Previous
                    </Button>
                    <span>{currentIndex + 1}/{totalSubmissions}</span>
                    <Button
                        variant="theme"
                        disabled={currentIndex + 1 === totalSubmissions}
                        className={`btn btn-default ${styles.wideButton}`}
                        onClick={() => {
                            setCurrentIndex(currentIndex + 1)
                            setCurrentIndex(currentIndex + 1)
                            setCurrentIndex(currentIndex + 1)
                        }}
                    >
                        Next
                    </Button>
                </div>
            </Col>
            <h1 className="h3 text-start pb-3 mt-4"><strong>{studentName}'s</strong> Submission</h1>
        </Row>
        <Row className="text-start overflow-auto">
            <Col xs={{ span: 12, order:  2}} md={{ span: 12, order: 2 }} lg={{ span: 9, order: 1 }}>
                {topicSubmission && <Pane className="shadow mb-3">
                        <TopicResponses
                            topicSubmission={topicSubmission}
                            setTopicSubmission={setTopicSubmission}
                            topic={topic}
                            studentTopicId={studentTopicId} />
                    </Pane>}
                {lessonSubmission && <Pane className="shadow">
                    <LessonResponses
                        lessonSubmission={lessonSubmission}
                        setLessonSubmission={setLessonSubmission}
                        lesson={lesson}
                        sumPoints={sumPoints}
                        assignment={state.assignment}
                        studentLessonId={studentLessonId}
                        expand={expand}
                        setExpand={setExpand}
                        collapsible={!!topicSubmission || !!assessmentSubmission} />
                    {/* Table Grading */}
                    <TableResponses
                        lessonSubmission={lessonSubmission}
                        setLessonSubmission={setLessonSubmission}
                        sumPoints={sumPoints}
                        lesson={lesson}
                        assignment={state.assignment}
                        studentLessonId={studentLessonId}
                        expand={expand} />
                </Pane>}
                <TeacherAssessmentResponses 
                    assessmentSubmission={assessmentSubmission} 
                    collapsible={!(!!assessmentOnly)}
                    refresh={toggleRefresh} />
            </Col>

            {/* Right side bar for topic submission */}
            {topicSubmission && 
                <Col xs={{ span: 12, order:  1 }} md={{ span: 12, order: 1 }} lg={{ span: 3, order: 1 }} className={`px-4 ${styles.lessonGradeContainer}`}>
                    <Pane className="shadow">
                        <LessonSidebar
                            topicSubmission={topicSubmission}
                            setTopicSubmission={setTopicSubmission}
                            topic={topic}
                            studentTopicId={studentTopicId}
                            lessonSubmission={lessonSubmission}
                            setLessonSubmission={setLessonSubmission}
                            lesson={lesson}
                            assignment={state.assignment}
                            studentLessonId={studentLessonId}
                            activeClass={activeClass}
                        />
                        {assessmentSubmission &&
                            <>
                                <hr />
                                {displayAssessmentSidebar()}
                            </>}
                    </Pane>
                </Col>}

            {/* Right side bar for lesson submission */}
            {lessonSubmission && !topicSubmission && 
                <Col xs={{ span: 12, order:  1 }} md={{ span: 12, order: 1 }} lg={{ span: 3, order: 1 }} className={`px-4 ${styles.lessonGradeContainer}`}>
                    <Pane className="shadow overflow-auto vh-100">
                        <LessonSidebar
                            lessonSubmission={lessonSubmission}
                            setLessonSubmission={setLessonSubmission}
                            lesson={lesson}
                            assignment={state.assignment}
                            studentLessonId={studentLessonId}
                            activeClass={activeClass}
                        />
                        {assessmentSubmission &&
                            <>
                                <hr />
                                {displayAssessmentSidebar()}
                            </>}
                    </Pane>
                </Col>}
            
            {/* Right side bar for assessment only submission */}
            {assessmentOnly &&
                <Col xs={{ span: 12, order:  1 }} md={{ span: 12, order: 1 }} lg={{ span: 3, order: 1 }} className={`px-4 ${styles.assessmentGradeContainer}`}>
                    <Pane className="shadow">
                        {displayAssessmentSidebar()}
                    </Pane>
                </Col>}
        </Row>
    </>
}
