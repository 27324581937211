import { CurriculumSection, TopicStudentAnswers } from "shared/types/curriculumTypes"
import SectionQuestions from "./SectionQuestions"
import { initTopicTable } from "components/Assignment/helpers/topicAssignmentHelper"
import { useEffect, useState } from "react"

interface Props {
    currentSection: CurriculumSection
    studentAnswers: TopicStudentAnswers
    setStudentAnswers: React.Dispatch<React.SetStateAction<{}>>
    handleAnswerChange: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
    debouncedSaveTopic: () => void
    initialDrawingData?: Record<string, string>
}

const TopicSection = ({ currentSection, studentAnswers, setStudentAnswers, handleAnswerChange, debouncedSaveTopic, initialDrawingData } : Props) => {

    const [initialStudentAnswers] = useState({...studentAnswers})

    /**
     * Load the existing topic table data and update the studentAnswers structure based on the table inputs
     */
    useEffect(() => {
        if (!currentSection) return
        initTopicTable({
            action: "load",
            sectionNumber: currentSection.sortOrder, 
            sectionId: currentSection.id,
            studentAnswers: initialStudentAnswers,
            setStudentAnswers: setStudentAnswers})
    }, [currentSection, initialStudentAnswers, setStudentAnswers])

    /**
     * Attach listeners to the topic table inputs
     */
    useEffect(() => {
        if (!currentSection) return
        initTopicTable({
            action: "attach",
            sectionNumber: currentSection.sortOrder, 
            sectionId: currentSection.id,
            setStudentAnswers: setStudentAnswers})
    }, [currentSection, setStudentAnswers])

    if (!currentSection) return <></>
  return (
        <div>
            <h1>{currentSection.header}</h1>
            <div className="text-start" dangerouslySetInnerHTML={{ __html: currentSection.sectionText }} />
            <SectionQuestions
                initialDrawingData={initialDrawingData}
                currentSection={currentSection}
                handleAnswerChange={handleAnswerChange}
                debouncedSaveTopic={debouncedSaveTopic}
                studentAnswers={studentAnswers} />
        </div>
  )
}

export default TopicSection
