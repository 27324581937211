import styles from "./styles.module.scss"
import variables from "../../scss/variables.module.scss"

interface Props {
    currentSectionNumber: number
    sectionHeaders: string[]
}

interface StepProps {
    active: boolean
    name: string
    cx: number
    lx: number
    isLastStep: boolean
}

const Step = ({active, name, cx, lx, isLastStep} : StepProps) => {
    const fillColor = variables["theme-color"]
    return <>
        <circle
            cx={`${cx}`}
            cy="25"
            r="10"
            fill={active ? fillColor : "#FFFFFF"}
            stroke="#000000" />
        <text
            textAnchor="middle"
            x={`${cx}`}
            y="47">{name}</text>
        {!isLastStep && <line
            x1={`${lx}`}
            x2={`${lx + 100}`}
            y1="25"
            y2="25"
            stroke="#000000" />}
    </>
}

const AssignmentTopicProgress = ({ currentSectionNumber, sectionHeaders } : Props) => {
    let cx = 0
    let lx = 0

    return (
        <svg
            viewBox="0 0 1000 50"
            className={styles.progressSvg}
            xmlns="http://www.w3.org/2000/svg">
                {sectionHeaders?.map((header, index) => {
                    cx += 100
                    lx = cx + 10
                    return <Step key={`${header}-${index}`} active={index <= currentSectionNumber} name={header} cx={cx} lx={lx} isLastStep={sectionHeaders?.length - 1 === index} />
                })}
        </svg>
    )
}

export default AssignmentTopicProgress
