import { Button, Modal } from "react-bootstrap"
import useBoolean from "helpers/useBoolean"
import styles from "../submissionTeacher.module.scss"

const ExampleDrawingAnswer = ({exampleDrawingPath, optionalAnswer} : {exampleDrawingPath: string, optionalAnswer?: string}) => {
    const [showModal, toggleModal] = useBoolean(false)
    return (
        <div>
            <Button variant="theme" onClick={toggleModal}>Show Example Drawing</Button>
            <Modal show={showModal} onHide={toggleModal} size={"xl"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Example Drawing Answer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {exampleDrawingPath && 
                        <img className={styles.tableAnswer} src={exampleDrawingPath} alt="Example Drawing Answer" />}
                    {optionalAnswer && <h3>{optionalAnswer}</h3>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ExampleDrawingAnswer
