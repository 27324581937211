import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { useModel } from "@stem-sims/nexus"
import generalStyles from "../General/general.module.scss"
import EmptyTablePreview from "../General/EmptyTablePreview"
import student from "shared/routes/student"
import { StudentAssignmentResponse, ClassResponse } from "shared/types/studentTypes"
import Assignments from "../General/Assignments"
import { getStudentSubmissionId, getTopicSubmissionId } from "helpers/getSubmissionId"
import { getSubmissions } from "shared/routes/assignments"
interface StudentAssignmentsProps {
    activeClass: ClassResponse
}


export default function StudentAssignments({ activeClass }: StudentAssignmentsProps) {
    const navigate = useNavigate()

    const { response: assignments, loading} = useModel({
        model: student.getAssignments,
        onError: (err) => (toast.error(err.response?.data?.message ?? "There has been an error loading the assignments. Please try again.")),
        props: { classId: activeClass?.id }
    })

    const openAssignment = async (assignment: StudentAssignmentResponse) => {
        try {
            let studentSubmissionId = null
            let startFromAssessment = false
            if (assignment?.topicId) {
                studentSubmissionId = await getTopicSubmissionId(assignment)
            } else {
                const recentSubmission = (await getSubmissions({ assignmentId: assignment.id })).data?.[0]
                studentSubmissionId = await getStudentSubmissionId(assignment)
                startFromAssessment = (recentSubmission?.lessonSubmittedDate && recentSubmission?.assessmentGrade === null && recentSubmission?.lessonGrade == null) ? true : false
            }
            navigate("/dashboard/student/assignments/assignment", {
                state: {
                    submissionId: studentSubmissionId,
                    assignment: assignment,
                    continueAssessment: startFromAssessment
                }
            })
        } catch (err) {
            toast.error(err?.response?.data?.message ?? "Unable to start assignment, please try again.")
        }
    }

    const extraCols = [
        {
            Header: 'Status',
            accessor: "status",
        },
    ]

    const onRowClick = (row) => {
        const assignment: StudentAssignmentResponse = row.original
        openAssignment(assignment)
    }

    const changeBackgroundColor = (row) => {
        const assignment: StudentAssignmentResponse = row.original
        return assignment.userHasAccess ? "" : `${generalStyles.unavailableAssignment}`
    }

    const AssignmentsComponent = <Assignments assignments={assignments} onRowClick={onRowClick} changeBackgroundColor={changeBackgroundColor} extraCols={extraCols} loading={loading} rowTooltip="Start the assignment" />
    const EmptyTableComponent = <EmptyTablePreview title="Assignments" description="Your teacher has not added any assignments yet." />
    return (<>
            <h1 className="text-start mt-3">Assignments</h1>
            <p className={`text-start ${generalStyles.greyText}`}>
                View the status of your assignments.
            </p>
            {(loading || assignments?.length > 0) ? AssignmentsComponent : EmptyTableComponent}
    </>)
}
