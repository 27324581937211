import Pane from "components/Dashboards/General/Pane";
import { Col, Row } from "react-bootstrap";
import styles from "./editingStyles.module.scss"
import { useNavigate } from "react-router-dom";

const { selectable } = styles

export default function EditingItems() {
    const navigate = useNavigate()

    return <>
        <Row className="mt-2 mx-2">
            <Col xs="6">
                <div onClick={() => window.location.href = "/simulations/categories"}>
                    <Pane className={selectable}>Categories</Pane>
                </div>
            </Col>
            <Col xs="6">
                <div onClick={() => navigate("curriculum/")}>
                    <Pane className={selectable}>Curriculum Textbooks</Pane>
                </div>
            </Col>
        </Row>
    </>
}
