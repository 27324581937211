import { Route, Routes, useLocation } from "react-router";
import EditingItems from "./EditingItems";
import EditingTextbooks from "./EditingTextbooks";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import EditingTextbook from "./EditingTextbook";
import FAIcon from "components/General/FAIcon";
import EditingUnit from "./EditingUnit";
import EditingTopic from "./EditingTopic";
import EditingSection from "./EditingSection";
import { useState } from "react";
import ImageLibrary from "./ImageLibrary";

export default function EditingDashboard() {
    const location = useLocation()

    const parentUrl = new URL("..", window.origin + location.pathname.replace(/\/$/, ""))

    const [modal, setModal] = useState<string>(null)

    return <>
        <Row>
            <Col className="mt-3">
                <Link to={parentUrl.pathname.includes("editing") ? parentUrl : "/editing"}>
                    <span><FAIcon iconName="arrow-up" /> Go Up a Level</span>
                </Link>
            </Col>

            <Col>
                <h2 className="my-3">Content Editing</h2>
            </Col>

            <Col>
                <Button className="mt-3" onClick={() => setModal("imageLibrary")}>Image Library</Button>
            </Col>
        </Row>
        <Routes>
            <Route path="/" element={<EditingItems />} />
            <Route path="/curriculum/" element={<EditingTextbooks />} />
            <Route path="/curriculum/book/:bookId" element={<EditingTextbook />} />
            <Route path="/curriculum/book/:bookId/unit/:unitId" element={<EditingUnit />} />
            <Route path="/curriculum/book/:bookId/unit/:unitId/topic/:topicId" element={<EditingTopic />} />
            <Route path="/curriculum/book/:bookId/unit/:unitId/topic/:topicId/section/:sectionId" element={<EditingSection />} />
        </Routes>

        <ImageLibrary modal={modal} setModal={setModal} />
    </>
}
