import { MODULE_NO_ACCESS, Type } from 'shared/types/moduleTypes'
import getURLParams from 'helpers/getURLParams'
import Assessment from './Assessment'
import { Button, Col, Dropdown, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { parseFormData } from 'helpers/formDataHelper'
import { useContext, useEffect, useState } from 'react'
import * as modules from "../../models/modules"
import moduleRoutes from "shared/routes/moduleRoutes"
import { checkIfAssessmentComplete } from './helpers/assessmentHelper'
import { AuthContext } from 'AuthContext'
import students from 'shared/routes/student'
import { ClassResponse } from 'shared/types/studentTypes'
import LessonHeader from 'components/Lessons/LessonModule/LessonHeader'

interface AssessmentWrapperProps {
    assessmentId?: string,
    moduleName?: string,
    assessmentNumber?: number,
    type?: Type
}

const AssessmentModule = ({ assessmentId, moduleName, assessmentNumber, type }: AssessmentWrapperProps) => {
    const props = getURLParams() as { assessmentId?: string, module?: string, assessmentNumber?: number, type?: Type}
    const [firstLoadTime] = useState(new Date().toISOString().slice(0,19).replace('T',' '))
    const [submitting, setSubmitting] = useState(false)
    const [drawingResponses, setDrawingResponses] = useState({})

    const { trackingEnabled, isLoggedIn, isStudent } = useContext(AuthContext)
    
    const submitAssessment = async () => {
        if (!isLoggedIn) {
            return toast.error("Please login to grade your assessment.")
        }

        const form = document.getElementById("assessment-form") as HTMLFormElement

        if (isStudent) {
            const classes: ClassResponse[] = await students.getClasses()
            // if student has tracking and their class requires completion, check form is completed
            if (trackingEnabled && classes[0].requireCompletion === "Yes") {
                const formCompleteCheck  = checkIfAssessmentComplete(form, Object.keys(drawingResponses).length)
                if (!formCompleteCheck[0]) {
                    return toast.error(formCompleteCheck[1])
                }
            }
        }

        setSubmitting(true)
        
        const postParams = { ...parseFormData(form, false), ...drawingResponses, startTime: firstLoadTime }
        try {
            const res = await modules.submitAssessment({
                moduleName: props.module,
                assessmentType: props.type,
                assessmentNumber: props.assessmentNumber,
                postParams: postParams
            })
            if(res.valid) {
                toast.success(res.message)
            } else {
                toast.error(res.message)
            }
        } catch (err) {
            if (err?.response?.data?.message === MODULE_NO_ACCESS) {
                return window.location.href = `/simulations/moduleName=${props?.module}/tab=challenge`
            }
            toast.error(
                err?.response?.data?.message ?? "There was an error submitting the assessment. Please try again."
            )
        }

        setSubmitting(false)
    }

    useEffect(() => {
        moduleRoutes.checkModuleAccess(props?.module)
        .then(userHasAccess => {
            if (!userHasAccess) {
                return window.location.href = `/simulations/moduleName=${props?.module}/tab=challenge`
            }
        })
    }, [props?.module])

  return (
    <Row className={`my-4`}>
            <Row className="text-start align-items-center m-0 my-2 w-75 mx-auto">
                <Col xs={6} md={"auto"}>
                    <Button variant="outline-primary" size="sm" className="mx-2 d-print-none" onClick={() => {
                        window.history.back()
                    }}>
                        <i className="fas fa-arrow-left" /> Back to Previous Page
                    </Button>
                </Col>
                <Col className="d-none d-md-block">
                    <LessonHeader moduleName={moduleName} /> 
                </Col>
                <Col xs={6} md={"auto"}>
                    <Row className="float-end text-center mb-2 g-1">
                        <Col>
                            <Dropdown className="m-2 d-print-none" align="end">
                                <Dropdown.Toggle variant="transparent" className="text-theme border rounded border-theme">
                                    <i className="far fa-file-alt" /> Documents
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="border-theme">
                                    <Dropdown.Header>Assessment Printouts</Dropdown.Header>
                                    <Dropdown.Item eventKey="2" href={`/api/assessments/${props.assessmentId}/guide?teacherView=false`}>Student PDF</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" href={`/api/assessments/${props.assessmentId}/guide?teacherView=true`}>Teacher Guide PDF</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Assessment 
                setDrawingResponses={setDrawingResponses}
                assessmentId={props.assessmentId} 
                moduleName={props.module} 
                assessmentNumber={props.assessmentNumber} 
                type={props.type} />
        <div>
            <Button className="btn-lg mb-4" id="assessment-submit" disabled={submitting} onClick={submitAssessment}>
                { trackingEnabled ? "Submit Assessment" : "Grade Assessment" }
            </Button>
        </div>
    </Row>
  )
}

export default AssessmentModule
