import { CurriculumSection } from "shared/types/curriculumTypes"

interface Props {
    section: CurriculumSection
}

export default function SectionPreviewPane({ section }: Props) {
    return <div className="container text-start">
        <h4 className="text-center">Section Preview</h4>
        <span dangerouslySetInnerHTML={{ __html: section?.sectionText }}></span>
        {section?.type === "Questions" && <>
            <ol>

                {section.questions?.map((question) => (<>
                    <li className="text-start"><span dangerouslySetInnerHTML={{ __html: question.question }} /></li>

                    {question.type === "MultipleChoice" && <>
                        <ol style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
                            {question.answers?.map((answer) => (<>
                                <div style={{ display: "flex" }}>
                                    <input type="radio" />
                                    <li style={{ display: "inline list-item", marginLeft: "1.5em", listStyleType: `"${answer.letter}. "` }}><span dangerouslySetInnerHTML={{ __html: answer.answer }} /></li>
                                </div>
                            </>))}
                        </ol>
                    </>}

                </>))}

            </ol>
        </>}

        {section?.type === "HTML" && <>
            {section.content?.map((content) => (<>
                <span dangerouslySetInnerHTML={{ __html: content.content }} />
            </>))}
        </>}
    </div>
}
