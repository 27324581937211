import styles from "./styles.module.scss"
import variables from "../../scss/variables.module.scss"

export default function AssignmentProgress({ active, hasTopicAssignment }: { active: "topic" | "lesson" | "assessment", hasTopicAssignment?: boolean }) {
    const fillColor = variables["theme-color"]
    return <svg
        viewBox="0 0 1000 50"
        className={styles.progressSvg}
        xmlns="http://www.w3.org/2000/svg"
    >
        {hasTopicAssignment && <>
            <circle
                cx="70"
                cy="25"
                r="10"
                fill={fillColor}
                data-testid="topic-circle"
                stroke="#000000" />
            <text
                textAnchor="middle"
                x="70"
                y="47">Topic</text>
            <line
                x1="80"
                x2="380"
                y1="25"
                y2="25"
                stroke="#000000" />
        </>
        }
        <circle
            cx="390"
            cy="25"
            r="10"
            fill={active !== "topic" ? fillColor: "#FFFFFF"}
            data-testid="lesson-circle"
            stroke="#000000" />

        <text
            textAnchor="middle"
            x="390"
            y="47">Lesson</text>
        <line
            x1="400"
            x2="700"
            y1="25"
            y2="25"
            stroke="#000000" />

        <circle
            cx="710"
            cy="25"
            r="10"
            fill={active === "assessment" ? fillColor : "#FFFFFF"}
            data-testid="assessment-circle"
            stroke="#000000" />

        <text
            textAnchor="middle"
            x="710"
            y="47">Assessment</text>
    </svg>
}
