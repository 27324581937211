import CustomHTML from "components/CustomHTML"
import { Image } from "react-bootstrap"
import { LessonSubmissionResponse, SubmissionResponse, TopicQuestionResponse, TopicSubmissionResponse } from "shared/types/moduleTypes"
import ItemGradeInput from "../components/ItemGradeInput"
import ExampleDrawingAnswer from "../components/ExampleDrawingAnswer"
import Pane from "components/Dashboards/General/Pane"
import { Dispatch, SetStateAction } from "react"
import styles from "../submissionTeacher.module.scss"

interface Props {
    responseType: "lesson" | "topic"
    index: number
    response: TopicQuestionResponse | SubmissionResponse
    updateQuestion: any
    submission: TopicSubmissionResponse | LessonSubmissionResponse
    setSubmission: Dispatch<SetStateAction<TopicSubmissionResponse | LessonSubmissionResponse>>
    sumPoints: (summary: TopicSubmissionResponse | LessonSubmissionResponse) => number
}

const DrawingResponseItem = ({ responseType, index, response, updateQuestion, submission, setSubmission, sumPoints } : Props) => {

    const updateGrade = (value: number) => {
        if (responseType === "topic" && "studentTopicAnswerId" in response) {
            const newTopic = { ...submission }
            newTopic.responses[index].grade = value
            newTopic.points = sumPoints(newTopic)
            newTopic.grade = newTopic.points / newTopic.totalPoints * 100
            setSubmission(newTopic)
            updateQuestion({ studentTopicAnswerId: response.studentTopicAnswerId, grade: response.grade })
        } else if (responseType === "lesson" && "section" in response) {
            const newLesson = { ...submission } as LessonSubmissionResponse
            newLesson.responses[index].grade = value
            newLesson.points = sumPoints(newLesson)
            newLesson.grade = (newLesson.points / newLesson.totalPoints) * 100
            setSubmission(newLesson)
            updateQuestion({ section: response.section, questionNumber: response.questionNumber, grade: value })
        }
    }

    const updateComment = (newValue: string) => {
        const newComment = newValue
        if (responseType === "topic" && "studentTopicAnswerId" in response) {
            if (newComment !== response.comment) {
                void updateQuestion({ studentTopicAnswerId: response.studentTopicAnswerId, comment: newComment })
            }
        } else if (responseType === "lesson" && "section" in response) {
            if (newComment !== response.comment) {
                void updateQuestion({
                    section: response.section,
                    questionNumber: response.questionNumber,
                    comment: newComment
                })
            }
        }
    }

  return (
    <Pane className="text-start my-4">
        <span className="pe-2"><span className="fw-bold">{response.questionNumber}.</span> <CustomHTML html={response.question} /></span>
        <p className="my-2"><strong>Student's Drawing Answer</strong></p>
        <Image src={response.drawingResponse} alt={'Student Drawing Answer'} className="rounded mb-2 shadow-sm border border-1" fluid/>
        <div>
            <p className="mb-2"><strong>Grade</strong></p>
            <ItemGradeInput
                currentValue={response.grade}
                setValue={(value) => updateGrade(value)}
            />
        </div>
        <div className="mt-3">
            <p className="mb-2"><strong>Comments</strong></p>
            <textarea
                className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                defaultValue={response.comment ?? ""}
                onBlur={(e) => updateComment(e.currentTarget.value)}
            />
        </div>
        <ExampleDrawingAnswer 
            exampleDrawingPath={response?.exampleDrawing}
            optionalAnswer={response?.correctAnswer} />
    </Pane>
  )
}

export default DrawingResponseItem
