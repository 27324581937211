import { useModel } from "@stem-sims/nexus"
import { useParams } from "react-router"
import { getTopic } from "shared/routes/curriculum/books"
import SectionPreviewPane from "./SectionPreviewPane"
import { useEffect, useState } from "react"

export default function SectionPreview() {
    const { sectionId, topicId } = useParams()

    const { response: topic, refreshModel } = useModel({
        model: getTopic,
        props: { topicId }
    })

    const [, setIntervalId] = useState<NodeJS.Timer>(null)

    useEffect(() => {
        setIntervalId((prev) => {
            if (prev) {
                clearInterval(prev)
            }

            return setInterval(refreshModel, 1000)
        })
    }, [refreshModel])

    const section = topic?.sections.find((section) => section.id === sectionId)

    return <>
        <SectionPreviewPane
            section={section}
        />
    </>
}
