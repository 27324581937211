import { useModel } from "@stem-sims/nexus"
import Pane from "components/Dashboards/General/Pane"
import FAIcon from "components/General/FAIcon"
import { Modal, Row, Col, Button, Form } from "react-bootstrap"
import { PulseLoader } from "react-spinners"
import { toast } from "react-toastify"
import { createImageFolder, getImages, uploadImage } from "shared/routes/curriculum/books"
import styles from "./editingStyles.module.scss"
import { useState } from "react"

const { selectable } = styles

type Props = {
    modal: string
    setModal: (modal: string) => void
}

const modals = ["imageLibrary", "newDirectory", "newImage"]

export default function ImageLibrary({ modal, setModal }: Props) {
    const [selectedPath, setSelectedPath] = useState("/")

    const { loading: imageLoading, response: root, refreshModel: refreshImages } = useModel({
        model: getImages,
        props: { relativePath: selectedPath },
    })

    return <>
        <Modal show={modal === "imageLibrary"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>
                <span>
                    Image Library - {selectedPath}
                </span>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ maxHeight: "80vh", overflow: "scroll" }}>

                    <Col xs={4} className="my-2" style={{ alignContent: "center" }}>
                        <Button
                            onClick={() => {
                                setSelectedPath((old) => {
                                    return old.slice(0, old.lastIndexOf("/", old.length - 2) + 1)
                                })
                            }}
                        ><FAIcon iconName="arrow-up" /> Parent</Button>
                    </Col>

                    <Col xs={4} className="my-2" style={{ alignContent: "center" }}>
                        <Button onClick={() => { setModal("newDirectory") }}>
                            <FAIcon iconName="plus" /> Folder
                        </Button>
                    </Col>

                    <Col xs={3} className="my-2" style={{ alignContent: "center" }}>
                        <Button onClick={() => { setModal("newImage") }}>
                            <FAIcon iconName="plus" /> Image
                        </Button>
                    </Col>

                    {imageLoading && <>
                        <PulseLoader />
                    </>}

                    {!imageLoading && root && <>

                        {root.subdirectories.map((dir) => (
                            <Col key={dir} xs={3} className="my-2" onClick={() => { setSelectedPath((old) => `${old}${dir}/`) }}>
                                <Pane className={selectable}><FAIcon iconName="folder" /> {dir}</Pane>
                            </Col>
                        ))}

                        {root.files.map((src) => (
                            <Col key={src} xs={3} className="my-2" onClick={() => {
                                setModal(src)
                            }}>
                                <Pane className={selectable}>
                                    <img alt="" style={{ maxWidth: "50%" }} src={`/images/curriculum${selectedPath}${src}`} />
                                </Pane>
                            </Col>
                        ))}
                    </>}
                </Row>
            </Modal.Body>
        </Modal>

        <Modal show={modal === "newDirectory"} onHide={() => setModal(null)}>
            <Form onSubmit={(event) => {
                event.preventDefault()
                const name = event.currentTarget.elements["name"].value
                toast.info(`Making ${name}, I pwomise`)
                createImageFolder({ directory: selectedPath, newDir: name }).then(() => {
                    setModal("imageLibrary")
                    refreshImages()
                })
            }}>
                <Modal.Header closeButton>Make New Directory</Modal.Header>

                <Modal.Body>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control></Form.Control>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button variant="theme" onClick={() => setModal("imageLibrary")}>Return</Button>
                        </Col>
                        <Col>
                            <Button variant="theme" type="submit">
                                Create
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Form>
        </Modal>

        <Modal show={modal === "newImage"} onHide={() => setModal(null)}>
            <Form onSubmit={(event) => {
                event.preventDefault()
                const image = event.currentTarget.elements["image"].files[0]
                uploadImage({ directory: selectedPath, image: image }).then((response) => {
                    navigator.clipboard.writeText(`<img src="/images/curriculum${selectedPath}${response.id}.png" />`).then(() => {
                        setModal("imageLibrary")
                        refreshImages()
                        toast.info("Copied img tag!", { autoClose: 1000 })
                    })
                })
            }}>
                <Modal.Header closeButton>Upload New Image</Modal.Header>

                <Modal.Body>
                    <Form.Group controlId="image">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="file" accept=".png,image/png,.jpg,.jpeg,image/jpeg"></Form.Control>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button variant="theme" onClick={() => setModal("imageLibrary")}>Return</Button>
                        </Col>
                        <Col>
                            <Button variant="theme" type="submit">
                                Upload
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Form>
        </Modal>

        <Modal show={modal && !modals.includes(modal)} onHide={() => setModal(null)}>
            <Modal.Header closeButton>Selected Image</Modal.Header>

            <Modal.Body>
                <img alt="" style={{ maxWidth: "80%", maxHeight: "80vh" }} src={`/images/curriculum${selectedPath}${modal}`} />
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col>
                        <Button variant="theme" onClick={() => setModal("imageLibrary")}>Return</Button>
                    </Col>
                    <Col>
                        <Button variant="theme" onClick={() => {
                            navigator.clipboard.writeText(`<img src="/images/curriculum${selectedPath}${modal}" />`).then(() => {
                                setModal(null)
                                toast.info("Copied img tag!", { autoClose: 1000 })
                            })
                        }}>
                            Copy
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    </>
}
