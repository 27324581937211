import { TopicSubmissionResponse } from 'shared/types/moduleTypes'
import LessonResponseItem from './components/LessonResponseItem'
import { AssessmentViewOptionsType } from 'shared/types/assessmentTypes'
import AssessmentResponseItem from './components/AssessmentResponseItem'
import DrawingResponseItem from './components/DrawingResponseItem'

interface Props {
    topicSubmission: TopicSubmissionResponse
    assessmentViewOption: AssessmentViewOptionsType
}

const StudentTopicResponses = ({ topicSubmission, assessmentViewOption } : Props) => {
  return (
    <>
        <h2 className="h3 text-start pb-3 fw-bold text-center">Topic Responses</h2>
        <ol className="mt-3">
            {topicSubmission.responses?.map((response, index) => {
                if (response.questionType === "Drawing") {
                    return <DrawingResponseItem questionNumber={String(response.questionNumber)} response={response} completed={topicSubmission.completed} index={index} />
                } else if (response.questionType === "Text") {
                    return <LessonResponseItem questionNumber={String(response.questionNumber)} response={response} completed={topicSubmission.completed} index={index} />
                } else {
                    return <AssessmentResponseItem response={response} index={index} assessmentViewOption={assessmentViewOption} />
                }
            })}
        </ol>
    </>
  )
}

export default StudentTopicResponses
