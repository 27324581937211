import * as React from "react"
import * as auth from "../../models/auth"
import { AuthContext } from "AuthContext"

const AuthRoute: React.FC<{ permission: auth.Permission, trackingEnabled?: boolean }> = ({ children, permission, trackingEnabled }) => {
    const authInfo = React.useContext(AuthContext)

    if (authInfo.loading) {
        return <></>
    }

    let isAuthed = false
    if (authInfo.permissions?.includes(permission)) {
        if (trackingEnabled && authInfo.trackingEnabled !== trackingEnabled) {
            window.location.href = "/"
        }
        else {
            isAuthed = true
        }
    } else {
        if (authInfo.isLoggedIn) {
            window.location.href = "/"
        } else {
            window.location.href = "/account/log-in"
        }
    }

    if (isAuthed) {
        return <>{children}</>
    } else {
        return <></>
    }
}

export default AuthRoute
