import { useModel } from "@stem-sims/nexus"
import { Button, Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router"
import { PulseLoader } from "react-spinners"
import { createBook, getBooks } from "shared/routes/curriculum/books"
import TextbookItem from "components/Curriculum/TextbookItem"
import FAIcon from "components/General/FAIcon"

export default function EditingTextbooks() {

    const navigate = useNavigate()

    const { loading: bookLoading, response: books, refreshModel } = useModel({
        model: getBooks,
    })

    if (bookLoading) {
        return <>
            <PulseLoader />
        </>
    }

    return <>
        <h3>Textbooks</h3>
        <Row className="mt-2 mx-2 align-items-center">
            {books?.map((book) => (
                <TextbookItem textbook={book} key={book.id} onClick={() => {
                    navigate(`book/${book.id}`)
                }} />
            ))}

            <Col xs={4}>
                <Button onClick={async () => {
                    await createBook()
                    refreshModel()
                }}>New Book <br /> <FAIcon iconName="plus" /></Button>
            </Col>
        </Row>
    </>
}
