import Pane from "components/Dashboards/General/Pane"
import { AssessmentSubmissionResponse } from "shared/types/moduleTypes"
import AssessmentResponseItem from "./AssessmentResponseItem"
import useBoolean from "helpers/useBoolean"
import { AssessmentGradeDrawing, gradeAssessmentDrawing } from "shared/routes/assessments/assessments"
import { toast } from "react-toastify"
import AssessmentDrawingResponseItem from "./AssessmentDrawingResponseItem"

interface Props {
    assessmentSubmission: AssessmentSubmissionResponse
    collapsible: boolean
    refresh?: () => void
}

const TeacherAssessmentResponses = ({ assessmentSubmission, collapsible, refresh } : Props) => {

    const [expand, toggleExpand] = useBoolean(true)

    const gradeStudentDrawing = async ({ studentAssessmentId, questionNumber, grade } : AssessmentGradeDrawing) => {
        await gradeAssessmentDrawing({ studentAssessmentId: studentAssessmentId, questionNumber: questionNumber, grade: grade })
        refresh() // refresh the assessment submission response
        toast.success(`You have marked the question ${questionNumber} ${grade}.`)
    }

    if (!assessmentSubmission) return <></>
    return <Pane className="my-3 shadow">
        <div className="d-flex justify-content-center">
            <h3 className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>Assessment Responses</h3>
            {collapsible && 
                <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={toggleExpand} />
            }
        </div>
        {expand &&
            <ol className="p-4">
                {assessmentSubmission.responses?.map((response, index) => {
                    if (response.isDrawing === "Yes") {
                        return (
                            <AssessmentDrawingResponseItem 
                                index={index}
                                response={response}
                                onGrade={(questionNumber, grade) => {
                                    gradeStudentDrawing({studentAssessmentId: assessmentSubmission.studentAssessmentId, questionNumber, grade})
                                }} />
                        )
                    } else {
                        return (
                            <AssessmentResponseItem
                                index={index}
                                response={response} />
                        )
                    }
                })}
            </ol>
        }
    </Pane>
}

export default TeacherAssessmentResponses
