import CustomHTML from "components/CustomHTML"
import Pane from "components/Dashboards/General/Pane"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"
import { SubmissionResponse, TopicQuestionResponse } from "shared/types/moduleTypes"

interface Props {
    response: TopicQuestionResponse | SubmissionResponse
    index: number
    assessmentViewOption: AssessmentViewOptionsType
}

const AssessmentResponseItem = ({ response, index, assessmentViewOption } : Props) => {
    const correct: boolean = response.answer === response.correctAnswer
    const showCorrectness = !!((assessmentViewOption === "Correct/Incorrect" || assessmentViewOption === "Answers") && response.answer)
    const showCorrectAnswer = !!(assessmentViewOption === "Answers" && response.correctAnswer && !correct && response.answer)

    return (<Pane className="mb-4 shadow-sm">
        <div className="text-start" key={index}>
            <span 
                className={"pe-2 " + showCorrectness ? (correct ? "text-success" : "text-danger") : "text-secondary"}>
                <span className="fw-bold">{response.questionNumber ?? (index + 1)}. </span> 
                <span dangerouslySetInnerHTML={{ __html: response.question }} />
                {showCorrectness && <i className={`fas ${correct ? `fa-check text-success` : `fa-times text-danger`} ms-2`} />}
            </span>
            <br /><br />
            {response.answer && 
                <div className="mb-2"><strong>Your Answer: </strong><CustomHTML html={response.answer} /></div>}
            {/* Only show the correct answer if correct answer for the question exists AND teacher allows student to view correct answer */}
            {(showCorrectAnswer &&
                <div className="mb-2"><strong>Correct Answer: </strong><CustomHTML html={response.correctAnswer} /></div>)}
        </div>
    </Pane>)
}

export default AssessmentResponseItem
