import axios from "axios"
import { AssessmentGradeOptionsType } from "shared/types/assessmentTypes"

export type AssessmentResponse = {
    module: string,
    type: string,
    number: number,
    lessonNumber: number,
    title: string,
    id: string,
    lessonId: string,
}

export type AssessmentGradeDrawing = {
    studentAssessmentId: string
    questionNumber: number
    grade: AssessmentGradeOptionsType
}

export function findById({ id }: { id: string }): Promise<AssessmentResponse> {
    return axios.get(`/api/assessments/${id}`).then((res) => res.data)
}

export function gradeAssessmentDrawing({ studentAssessmentId, questionNumber, grade }: AssessmentGradeDrawing) {
    return axios.post("/api/assessments/submissions/grade", {studentAssessmentId, questionNumber, grade}).then((res) => res.data)
}

const assessments = {
    findById,
}

export default assessments
