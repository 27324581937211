import axios from "axios"

export type Category = {
    id: string,
    category: string,
    index: number
}

export async function getCategories(): Promise<Category[]> {
    const response = await axios.get("/api/categories")
    return response.data.categories
}

const categories = {
    getCategories,
}

export default categories
