import Dropdown from "../../Sidebar/Dropdown"
import SidebarLinks, { SidebarLink } from "../../Sidebar/Links"
import Sidebar from "../../Sidebar/Sidebar"
import findById from "../../../helpers/findById"
import CommonClass from "./Types/CommonClass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { useContext } from "react"
import { SidebarContext } from "components/Sidebar/SidebarContext"

interface Props<Type extends CommonClass> {
    activeClass: Type
    classes: Type[]
    onClassSelect: (selectedClass: Type) => void
    activeLinks: SidebarLink[]
    onAddClass?: () => void
    addClassLabel?: string
    showArchived?: boolean
    isStudent?: boolean
}

export default function ClassSidebar<Type extends CommonClass>({ activeClass, classes, onAddClass, onClassSelect, addClassLabel, activeLinks, showArchived = false , isStudent = false}: Props<Type>) {

    const { collapsed, toggleCollapse } = useContext(SidebarContext)

    return (
        <Sidebar>
            <div className={`d-flex align-items-center ${collapsed ? '' : 'border-bottom'}`}>
                {!isStudent &&
                    <Dropdown
                        title={"Select a Class"}
                        activeItem={{
                            title: activeClass ? `${activeClass?.className}${activeClass?.archived === "Yes" ? ` [Archived]` : ""}` : null,
                            id: activeClass?.id
                        }}
                        listItems={classes
                            .filter((item) => (showArchived || item.archived === "No"))
                            .sort((a, b) => a.className.localeCompare(b.className))
                            .map((item) => ({ title: `${item?.className}${item?.archived === "Yes" ? ` [Archived]` : ""}`, id: item.id }) )}
                        onSelect={(selectedClassId) => {
                            onClassSelect(
                                findById(selectedClassId, classes)
                            )
                        }}
                        onAddItem={onAddClass}
                        addItemLabel={addClassLabel}
                    />
                }
                {isStudent &&
                    <h3 className="ms-5 mt-3 mb-3">{activeClass?.className}</h3>
                }
                    {collapsed ? (
                            <FontAwesomeIcon 
                                role="button"
                                icon={faAngleRight} 
                                className="flex-fill ms-5 mb-2" 
                                size="lg"
                                onClick={toggleCollapse} />
                        ) : (
                            <FontAwesomeIcon 
                                role="button"
                                icon={faAngleLeft} 
                                className="flex-fill"
                                size="lg"
                                onClick={toggleCollapse} />
                        )
                    }
            </div>
            {activeClass === null ? "" :
                <SidebarLinks links={activeLinks} />
            }
        </Sidebar>
    )
}
