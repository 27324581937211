import { useModel } from "@stem-sims/nexus"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { PulseLoader } from "react-spinners"
import { createUnit, deleteUnit, getBooks, getUnits, updateBook, updateUnit } from "shared/routes/curriculum/books"
import React from "react"
import EditingItemPane from "./EditingItemPane"
import EditingListPane from "./EditingListPane"

export default function EditingTextbook() {

    const { loading: bookLoading, response: books, refreshModel: bookRefresh } = useModel({
        model: getBooks,
    })

    const { bookId } = useParams()

    const book = books?.find((book) => book.id === bookId)

    const { loading: unitLoading, response: units, refreshModel, } = useModel({
        model: getUnits,
        props: { bookId }
    })

    const onSubmitCallback = React.useCallback(async (values) => {
        await updateBook({
            bookId: bookId,
            name: values.name,
            description: values.description,
            image: values.image,
            visible: values.visible,
            exclusive: values.exclusive,
        })
        bookRefresh()
    }, [bookId, bookRefresh])

    const createUnitCallback = React.useCallback(async () => {
        await createUnit({
            bookId: bookId,
        })
        refreshModel()
    }, [refreshModel, bookId])

    const updateUnitCallback = React.useCallback(async (values) => {
        await updateUnit({
            unitId: values.id,
            order: values.order,
        })
        refreshModel()
    }, [refreshModel])

    const deleteUnitCallback = React.useCallback(async (values) => {
        await deleteUnit({ unitId: values.id })
        refreshModel()
    }, [refreshModel])

    if (!book && bookLoading) {
        return <>
            <PulseLoader />
        </>
    }

    return <>
        <h3>Book {book.name}</h3>
        <Row>
            <Col>
                <EditingItemPane
                    data={book}
                    fields={[
                        { fieldName: "name", inputType: "input" },
                        { fieldName: "description", inputType: "textarea" },
                        { fieldName: "image", inputType: "image" },
                        { fieldName: "visible", inputType: "checkbox" },
                        { fieldName: "exclusive", inputType: "checkbox" },
                    ]}
                    itemTypeName="book"
                    onSubmit={onSubmitCallback}
                />
            </Col>

            <Col>
                <h4>Units</h4>
                {!units && unitLoading && <>
                    <PulseLoader />
                </>}

                {(units || !unitLoading) && <>
                    <EditingListPane
                        itemType="unit"
                        items={units ?? []}
                        createItem={createUnitCallback}
                        updateItem={updateUnitCallback}
                        deleteItem={deleteUnitCallback}
                    />
                </>}
            </Col>
        </Row>
    </>
}
