import React, {  } from "react"
import Accordion from "react-bootstrap/Accordion"
import BeatLoader from "react-spinners/BeatLoader"
import lessonModel, { LessonResponse, LessonSearchItem, FindStandardsResponse } from "shared/lessons"
import CustomHTML from "../../CustomHTML"
import styles from "./styles.module.scss"
import ToolTip from "../../General/ToolTip"
import * as categoriesModel from "models/categories"
import PureInquiryDescription from "../PureInquiry/PureInquiryDescription"
import { tierToGradeRange } from "shared/types/moduleTypes"
import { Row, Col } from "react-bootstrap"

interface ResultProps {
    activeClassStandard?: categoriesModel.Standard
    filteredStandard?: categoriesModel.Standard
    lesson: LessonSearchItem
    lessonSelection: (LessonSearchItem) => any
    moduleAvailable?: boolean
}

export default function Result({ lesson, lessonSelection, activeClassStandard, filteredStandard, moduleAvailable }: ResultProps) {
    const [selectedLesson, setSelectedLesson] = React.useState<LessonResponse>(null)
    const [activeClassStandards, setActiveClassStandards] = React.useState<FindStandardsResponse["standards"]>([])
    const [filteredStandards, setFilteredStandards] = React.useState<FindStandardsResponse["standards"]>([])
    const [lessonLoading, setLessonLoading] = React.useState(true)
    const lessonAvailable = lesson.available && moduleAvailable

    const formatStandards = (standards: FindStandardsResponse["standards"]) => {
        return standards.map((standard, index) =>
            `${standard.topicCode} - ${standard.subtopicCode}${index !== standards.length - 1 ? ", " : ""}`
        )
    }

    return (
        <Accordion.Item eventKey={lesson.id + lesson.variety + lesson.type} className="rounded mb-3">
            <Accordion.Header className={(!lessonAvailable ) && styles.bgDesaturated} onClick={() => {
                //check if the lesson is already loaded
                if (lesson.variety === "Pure Inquiry") {
                    setSelectedLesson({
                        id: lesson.id,
                        moduleName: lesson.module,
                        number: 0,
                        variety: "Pure Inquiry",
                        type: lesson.type,
                        title: lesson.title,
                        description: null,
                        teacherPurpose: null,
                        teacherTips: null,
                    })
                    setLessonLoading(false)
                } else if (!selectedLesson) {
                    Promise.all([
                        activeClassStandard?.id ? 
                            lessonModel.findStandardCorrelations({
                                lessonId: lesson.id,
                                standardId: activeClassStandard.id
                            })
                            :
                            Promise.resolve(),
                            filteredStandard ? 
                            lessonModel.findStandardCorrelations({
                                lessonId: lesson.id,
                                standardId: filteredStandard.id
                            })
                            :
                            Promise.resolve(),
                        lessonModel.findById(lesson.id)
                    ])
                        .then(([activeClassStandardsResponse, filteredStandardsResponse, lessonResponse]) => {
                            setSelectedLesson({...lessonResponse, id: lesson.id })
                            if (activeClassStandardsResponse) {
                                setActiveClassStandards(activeClassStandardsResponse.standards)
                            }
                            if (filteredStandardsResponse) {
                                setFilteredStandards(filteredStandardsResponse.standards)
                            }
                        })
                        .then(() => setLessonLoading(false))
                }
            }}>
                <h2 className="my-auto">
                    {lesson.core &&
                        <span>
                            <ToolTip title="This lesson is marked as a core lesson for the selected standards">
                                <i className={`fas fa-star ${styles.resultStar}`} />
                            </ToolTip>
                        </span>
                    }
                    {lesson.variety === "Pure Inquiry" ? 
                        <span className="h6"><strong>{lesson.title}</strong> ({tierToGradeRange[lesson.type]})</span>
                        : 
                        <>
                            <span className="h6 align-middle fw-bold">Lesson {lesson.number}: </span>
                            <span className="h6 align-middle">
                                <strong><CustomHTML html={lesson.title} /></strong>
                                <span> ({tierToGradeRange[lesson.type]})</span>
                            </span>
                        </>
                    }
                </h2>
            </Accordion.Header>
            <Accordion.Body className={!lessonAvailable && styles.bgDesaturated}>
                {lessonLoading ?
                    <BeatLoader />
                    :
                    <Row>
                        <Col sm="3">
                            <img className="img-fluid rounded" src={lesson.thumbnail} alt={`${lesson.title}'s Thumbnail`}></img>
                        </Col>
                        <Col sm="9">
                            {lessonAvailable && lessonSelection(selectedLesson)}
                            {lesson.variety === "Pure Inquiry" &&
                                <p className="fw-bold h4 text-start">Pure Inquiry</p>
                            } 
                            {lesson.variety !== "Pure Inquiry" && <>
                                <p className="fw-bold h4 text-start">Lesson {lesson?.number}</p>
                                <p className="fw-light h4 text-start mb-3">
                                    <CustomHTML html={selectedLesson.title} /> 
                                    <span className="text-muted h5 ps-3">({tierToGradeRange[lesson.type]})</span>
                                </p>
                            </>}
                            {lesson.variety === "Pure Inquiry" &&
                                <PureInquiryDescription type={lesson.type} />
                            }
                            {lesson.variety === "Guided" &&
                                <p className="text-start">
                                    <CustomHTML html={selectedLesson.description} />
                                </p>
                            }
                            { (activeClassStandards.length > 0 || filteredStandards.length > 0) &&
                            <div className="text-secondary text-start">
                                Standards:

                                {filteredStandard && filteredStandards.length > 0 && <>
                                    <p className="mb-1 mt-3">{ filteredStandard.standard }</p>
                                    <p>
                                        { formatStandards(filteredStandards) }
                                    </p>
                                </>}
                            </div>
                            }
                        </Col>
                    </Row>
                }
            </Accordion.Body>
        </Accordion.Item>
    )
}
