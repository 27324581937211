import styles from "./styles.module.scss"
import { sanitizePath } from "shared/helpers/moduleHelper"

type LessonHeaderProps = {
    moduleName: string
}

const LessonHeader = ({moduleName}: LessonHeaderProps) => {
    return(<>
        {moduleName &&
            <div className={`${styles.heightFit}`}>
                <img src={`/simulations/${ sanitizePath(moduleName) }/lessons/header.png`} className="img-fluid center-block" alt={moduleName}/>
            </div>
        }
    </>)
}

export default LessonHeader
