export const checkIfAssessmentComplete = (form: HTMLFormElement, drawingResponsesNo?: number): [boolean, string] => {
    if(!form) {
        return [false, "Could not verify the assessment. Please contact STEM Sims support."]
    }
    const questionGroups = form.querySelectorAll(".question-group")
    const formElements = form.elements
    const radios = [...formElements]

    const validNodePattern = /^q\d+a\d+$/
    const checkedRadios = radios.filter(node => node["checked"] && validNodePattern.test(node.id))
    if (questionGroups.length > (checkedRadios.length + (drawingResponsesNo ?? 0))) {
        return [false, "Please fill out the entire assessment before submitting"]
    }
    return [true, "Assessment is completed."]
}

export const getUnansweredQuestions = (form: HTMLFormElement, drawingResponses: Record<any, string>): string[] => {
    if(!form) {
        return
    }

    const questionNumbers = [...form.querySelectorAll(".question-group")].map(qg => qg.id)
    const formElements = form.elements
    const radios = [...formElements]

    const unanswered = []

    const validNodePattern = /^q\d+a\d+$/
    const checkedRadios = radios.filter(node => node["checked"] && validNodePattern.test(node.id))
    const answered = checkedRadios.map(radio => {
        const a = document.getElementById(radio.id)
        const questionNum = a.dataset["qNo"]
        return questionNum
    })

    for (let drawingRes of Object.keys(drawingResponses)) {
        answered.push(drawingRes.split('-')[1])
    }
    
    // Only get the question numbers that are not in the answered array
    for (let i of questionNumbers) {
        if (!answered.includes(i.toString())) {
            unanswered.push(i.toString())
        }
    }

    return unanswered
}
