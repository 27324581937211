import { AssessmentSubmissionResponse } from "shared/types/moduleTypes"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"
import AssessmentDrawingResponseItem from "./components/AssessmentDrawingResponseItem"
import AssessmentResponseItem from "./components/AssessmentResponseItem"

interface Props {
    assessmentSubmission: AssessmentSubmissionResponse
    assessmentViewOption: AssessmentViewOptionsType
}

const StudentAssessmentResponses = ({ assessmentSubmission, assessmentViewOption } : Props) => {
    if (assessmentSubmission === null || assessmentViewOption === "Grade") return <></>

    return <>
        <h2 className="h3 text-start pb-3 fw-bold text-center">Assessment Responses</h2>
        <ol className="mt-3">
            {assessmentSubmission.responses?.map((response, index) => {
                if (response.isDrawing === "Yes") {
                    return <AssessmentDrawingResponseItem index={index} response={response} assessmentViewOption={assessmentViewOption} />
                } else {
                    return <AssessmentResponseItem index={index} response={response} assessmentViewOption={assessmentViewOption} />
                }
            })}
        </ol>
    </>
}

export default StudentAssessmentResponses
