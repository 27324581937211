import { ExcalidrawImageElement, FileId } from "@excalidraw/excalidraw/types/element/types"
import { DataURL } from "@excalidraw/excalidraw/types/types"
import { Section } from "shared/lessons"

export const convertBlobToBase64 = async (blob: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = async function () {
            resolve(reader.result)
        }
    })
}

export const getImgDimensions = (base64String) : Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve({ width: Math.min(img.width, 700), height: Math.min(img.height, 400) })
      }
      img.src = base64String
    })
}

export const loadImage = async ({ initialDrawingData, imagePath, fileId, imageId } : { initialDrawingData?: any, imagePath: string, fileId: string, imageId: string }) => {
    let response = null
    if (initialDrawingData) {
      response = await fetch(initialDrawingData)
    } else {
      response = await fetch(imagePath)
    }
    if (!response.ok) return
    const blob = await response.blob()
    const imageType = blob.type

    if (imageType !== "image/jpeg" && imageType !== "image/png") {
      return
    }

    const base64String = await convertBlobToBase64(blob)
    const dimensions = await getImgDimensions(base64String)

    const imageElement: ExcalidrawImageElement = {
      type: "image",
      id: imageId,
      status: "saved",
      fileId: fileId as FileId,
      version: 2,
      versionNonce: Date.now(),
      x: 800,
      y: 800,
      width: dimensions.width,
      height: dimensions.height,
      scale: [1, 1],
      isDeleted: false,
      fillStyle: "hachure",
      strokeWidth: 1,
      strokeStyle: "solid",
      roughness: 1,
      opacity: 100,
      groupIds: [],
      strokeColor: "#000000",
      backgroundColor: "transparent",
      seed: Date.now(),
      roundness: null,
      angle: 0,
      frameId: null,
      boundElements: null,
      updated: 1,
      locked: true,
      link: null,
    }

    return {
      elements: [imageElement],
      scrollToContent: true,
      files: {
        [imageElement.fileId]: {
          mimeType: imageType,
          id: imageElement.fileId,
          dataURL: base64String as DataURL,
          created: Date.now(),
        },
      }
    }
}

/**
 * 
 * Creates an object with keys for each drawing lesson question in the format 'question-:sectionNumber-:questionNumber' and assign null to each key
 */
export const initLessonDrawingResponses = async (sections: Section[]) => {
  const data = {}
  sections.forEach((section, sectNumber) => {
    section?.content.forEach(task => {
      if (task.category === "Question" && task.isDrawing === "Yes") {
        data[`question-${sectNumber + 1}-${task.index}`] = null
      }
    })
  })
  return data
}

/**
 * 
 * Load the initial lesson drawing data from previous attempt
 */
export const loadLessonDrawingData = async (initialDrawingData: any, data: any) => {
  if (!initialDrawingData) return data
  const res = {}
  const promises = Object.keys(data).map(async key => {
    if (!initialDrawingData[key]) return res[key] = null
    const response = await fetch(initialDrawingData[key])
    if (!response.ok) return
    const blob = await response.blob()
    const base64String = await convertBlobToBase64(blob)
    res[key] = base64String
  })
  await Promise.all(promises)
  return res
}

/**
 * 
 * Convert an AWS S3 drawing image url to a base64 string 
 */
export const convertImgToBase64 = async (url: string) => {
  const response = await fetch(url)
  if (!response.ok) return
  const blob = await response.blob()
  const base64String = await convertBlobToBase64(blob)
  return base64String
}
