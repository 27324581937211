import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw"
import { useContext, useEffect, useState } from "react"
import { ExcalidrawImperativeAPI, ExcalidrawInitialDataState } from "@excalidraw/excalidraw/types/types"
import Spinner from 'react-bootstrap/Spinner'
import styles from "./DrawingQuestion.module.scss"
import { Question } from "shared/lessons"
import CustomHTML from "components/CustomHTML"
import { convertBlobToBase64, loadImage } from "../helpers/drawingHelper"
import { LessonContext } from "components/Lessons/LessonContext"
import { CurriculumQuestion } from "shared/types/curriculumTypes"

interface Props {
  questionType: "lesson" | "assessment" | "topic"
  setDrawingResponses: React.Dispatch<React.SetStateAction<{}>>

  // lesson drawing question props
  lessonQuestionIndex?: string
  lessonQuestion?: Question
  sectionNumber?: number // also used for topic
  autoSave?: () => void
  minimizeCanvas?: () => void

  // assessment drawing question props
  questionNumber?: number
  questionImage?: string

  // topic drawing question props
  topicQuestion?: CurriculumQuestion
  handleAnswerChange?: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
  initialTopicDrawingData?: string
}

const DrawingQuestion = ({ questionType, setDrawingResponses, lessonQuestionIndex, lessonQuestion, sectionNumber, autoSave, minimizeCanvas, questionImage, questionNumber, topicQuestion, handleAnswerChange, initialTopicDrawingData } : Props) => {
  const [excaliAPI, setExcaliAPI] = useState<ExcalidrawImperativeAPI>(null)
  const [saving, setSaving] = useState(false)
  const [initialData, setInitialData] = useState<ExcalidrawInitialDataState>(null)
  const [hasStartedDrawing, setHasStartedDrawing] = useState<boolean>(false)
  const { drawingResponses } = useContext(LessonContext)

  const save = () => {
    setSaving(true)
    saveDrawing()
    .then(() => setTimeout(() => setSaving(false), 3000))
  }

  const saveDrawing = async () => {
    if (!excaliAPI) return

    const blob = await exportToBlob({
      elements: excaliAPI?.getSceneElements(),
      mimeType: "image/png",
      appState: {
        ...excaliAPI.getAppState,
      },
      files: excaliAPI?.getFiles()
    })

    const base64String = await convertBlobToBase64(blob)
    if (questionType === "lesson") {
      setDrawingResponses(prev => ({...prev, [`question-${sectionNumber}-${lessonQuestion.index}`]: base64String}))
      autoSave?.()
    } else if (questionType === "assessment") {
      setDrawingResponses(prev => ({...prev, [`question-${questionNumber}`]: base64String}))
    } else {
      handleAnswerChange(sectionNumber, topicQuestion?.sortOrder, "", base64String as string)
      autoSave()
    }
  }

  /**
   * Load initial drawing immage (if resuming previous attempt) instead of original lesson question image
   */
  useEffect(() => {
    const fileId = `file-${questionType}-${lessonQuestion?.index ?? questionNumber ?? topicQuestion?.sortOrder}`
    const imageId = `image-${questionType}-${lessonQuestion?.index ?? questionNumber ?? topicQuestion?.sortOrder}`

    if (questionType === "lesson") {
      loadImage({ initialDrawingData: drawingResponses?.[`question-${sectionNumber}-${lessonQuestion.index}`], imagePath: lessonQuestion.imagePath, fileId, imageId }).then(res => {
        setInitialData(res)
      })
    } else if (questionType === "assessment") {
      loadImage({ initialDrawingData: null, imagePath: questionImage, fileId, imageId }).then(res => {
        setInitialData(res)
      })
    } else {
      if (topicQuestion?.questionImageId && !topicQuestion?.questionImageUrl) return // this ensures topic question image url is loaded properly
      loadImage({ initialDrawingData: initialTopicDrawingData, imagePath: topicQuestion.questionImageUrl, fileId, imageId }).then(res => {
        setInitialData(res)
      })
    }

    return () => {
      setInitialData(null)
    }
    // eslint-disable-next-line
  }, [lessonQuestion?.imagePath, lessonQuestion?.index, questionType, sectionNumber, questionImage, topicQuestion])

  if (initialData === null) return <></>

  return (
    <>
        {questionType === "lesson" && 
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span>{lessonQuestionIndex}. <CustomHTML html={lessonQuestion.content} /></span>
            <i role="button" className={`fas fa-close iconButton me-2 py-1 text-dark`} onClick={minimizeCanvas}/>
          </div>}
        <div 
          className={styles.canvas}
          onWheelCapture={e => e.stopPropagation()} // prevent scrolling
          >
            <Excalidraw
              initialData={initialData}
              onPointerDown={() => setHasStartedDrawing(true)}
              onPointerUpdate={(e) => {
                if (e.button === "up" && hasStartedDrawing) {
                  save()
                  setHasStartedDrawing(false)
                }
              }}
              autoFocus={false}
              excalidrawAPI={api => setExcaliAPI(api)}
              zenModeEnabled={false}
              viewModeEnabled={false}
              UIOptions={{
                tools: { image: false }
              }} />
        </div>
        <div className="m-auto d-flex justify-content-center mt-2 p-3">
          {saving ?
              <div className="text-muted p-0 d-flex align-items-center">
                <span className="me-2">Saving your drawing</span>
                <Spinner animation="border" role="status" size="sm">
                </Spinner>
              </div> : <div className="text-muted">The drawing will automatically save as you draw on the canvas.</div>}
        </div>
    </>
  )
}

export default DrawingQuestion
