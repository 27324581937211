import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import BeatLoader from 'react-spinners/BeatLoader'
import { ClassResponse, StudentResponse } from '../../../shared/types/teacherTypes'
import styles from './teacher.module.scss'
import * as teacher from '../../../shared/routes/teacher'
import * as assignmentsModel from "shared/routes/assignments"
import ToolTip from 'components/General/ToolTip'
import { useModel } from '@stem-sims/nexus'
import { formatToNumericalDate } from 'helpers/dateHelper'
import lessons from 'shared/lessons'
import { useNavigate } from 'react-router'
import { TeacherGradesState } from 'components/Lessons/Grading/GradeList/TeacherGrades'
import EmptyTablePreview from '../General/EmptyTablePreview'

interface GradeBookProps {
    activeClass: ClassResponse
    refreshClasses: () => void
}

export default function GradeBook({ activeClass, refreshClasses }: GradeBookProps) {
    const [students, setStudents] = useState<StudentResponse[]>([])
    const [loadingStudents, setLoading] = useState(false)

    const sort = React.useRef<"first" | "last">(localStorage.getItem("studentSortOrder") as "first" | "last" ?? "last")

    const studentSort = (s1: StudentResponse, s2: StudentResponse) => {
        const firstStudentSort = s1.name.split(" ").at(sort.current === "first" ? 0 : -1)
        const secondStudentSort = s2.name.split(" ").at(sort.current === "first" ? 0 : -1)
        return firstStudentSort.localeCompare(secondStudentSort)
    }

    const refreshStudents = () => {
        setLoading(true)
        return teacher.getStudents({ classID: activeClass.id }).then((teacherStudents) => {
            setStudents(teacherStudents.sort(studentSort))
            setLoading(false)
        }).catch(() => {
            //Does the class still exist?
            toast.dismiss()
            toast.error("There was an issue getting your students.")
            refreshClasses()
        })
    }

    function assignmentError(err) {
        toast.error(err.response?.data?.message ?? "There has been an error loading the assignments. Please try again.")
        refreshClasses()
    }

    const { response: assignments, loading, refreshModel: reloadAssignments } = useModel({
        model: teacher.getAssignments,
        onError: assignmentError,
        props: { classID: activeClass.id }
    })

    useEffect(() => {
        reloadAssignments()
    },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeClass.id])

    useEffect(() => {
        void refreshStudents()
    },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeClass.id])

    if (loading || loadingStudents) {
        return (
            <div className="d-table h-100 mx-auto">
                <div className="d-table-cell text-center align-middle">
                    <BeatLoader size={15} />
                </div>
            </div>
        )
    }

    return (
        <div className="mx-2">
            <h1 className="h2 text-start mt-3">Class Grades</h1>
            <GradeBookTable assignments={assignments} students={students} />
        </div>
    )
}


function GradeBookTable({ assignments, students }) {
    const navigate = useNavigate()

    const calculateClassAverage = (assignemnt) => {
        let totalClassAverage = 0
        let totalStudentSubmissions = 0

        for (const submission of assignemnt.submissions) {
            let currentAssignmentAverage = 0

            if (submission?.lesson?.grade) {
                currentAssignmentAverage += submission.lesson.grade
            }

            if (submission?.assessment) {
                currentAssignmentAverage += (submission.assessment.numberCorrect / submission.assessment.numberOfQuestions) * 100
            }

            if (submission?.assessment || submission?.lesson?.grade) {
                totalStudentSubmissions += 1
            }
            if (submission?.lesson?.grade && submission?.assessment) currentAssignmentAverage /= 2
            totalClassAverage += currentAssignmentAverage
        }

        // Handling assignments that are overdue
        if (assignemnt.dueDate && new Date(assignemnt.dueDate) < new Date()) totalStudentSubmissions = students.length
        totalClassAverage /= totalStudentSubmissions

        if (totalClassAverage === 0) return 0
        if (isNaN(totalClassAverage)) return ""
        return `${totalClassAverage.toFixed(2)}%`
    }

    const calculateStudentGrade = (student) => {
        let totalStudentSubmissions = 0
        let totalClassGrade = 0

        for (const assignment of assignments) {
            const studentSubmission = assignment.submissions.find(s => s.studentId === student.uuid)
            let currentAssignmentGrade = 0

            if (studentSubmission?.assessment || studentSubmission?.lesson?.grade) {
                totalStudentSubmissions += 1
            }

            if (studentSubmission?.lesson?.grade) {
                currentAssignmentGrade += studentSubmission.lesson.grade
            }
            if (studentSubmission?.assessment) {
                currentAssignmentGrade += (studentSubmission.assessment.numberCorrect / studentSubmission.assessment.numberOfQuestions) * 100
            }

            // to handle for assignemnts that haven't been submitted before the due date
            if (!studentSubmission?.assessment && !studentSubmission?.lesson?.grade && assignment.dueDate && new Date() > new Date(assignment.dueDate)) {
                totalStudentSubmissions += 1
            }

            if (studentSubmission?.lesson?.grade && studentSubmission?.assessment) currentAssignmentGrade /= 2
            totalClassGrade += currentAssignmentGrade
        }
        if (totalStudentSubmissions === 0) return ""

        const studentClassGradePercentage = totalClassGrade / totalStudentSubmissions
        if (isNaN(studentClassGradePercentage)) return ""

        let gradeLetter: string
        if (studentClassGradePercentage >= 90 && studentClassGradePercentage <= 100) gradeLetter = 'A'
        else if (studentClassGradePercentage >= 80 && studentClassGradePercentage < 90) gradeLetter = 'B'
        else if (studentClassGradePercentage >= 70 && studentClassGradePercentage < 80) gradeLetter = 'C'
        else if (studentClassGradePercentage >= 60 && studentClassGradePercentage < 70) gradeLetter = 'D'
        else if (studentClassGradePercentage >= 0 && studentClassGradePercentage < 60) gradeLetter = 'F'

        return `${studentClassGradePercentage.toFixed(2)}% ${gradeLetter}`
    }

    const formatStudentAssignmentGrade = (student, assignment) => {
        const submission = student.submissions.find(s => s.studentClassAssignmentId === assignment.id)
        let studentGrade = 0

        if (submission?.lesson?.grade) {
            studentGrade += submission.lesson.grade
        }
        if (submission?.assessment) {
            studentGrade += (submission.assessment.numberCorrect / submission.assessment.numberOfQuestions) * 100
        }

        if (submission?.lesson?.grade && submission?.assessment) studentGrade /= 2

        // Check for an over due assignment that hasn't been submitted
        if (assignment.dueDate && !submission?.assessment && !submission?.lesson?.grade && new Date() > new Date(assignment.dueDate)) return ["Late", 0]

        if (!submission?.assessment && !submission?.lesson?.grade) return ["Not Submitted", ""]
        if (studentGrade === 0) return ["Submitted", 0]

        return ["Submitted", `${(studentGrade).toFixed(2)}%`]
    }

    const handleAssignmentClick = async (assignment) => {
        if (assignment.lessonVariety === "Guided") {
            if (assignment.lessonId) {
                var lesson = await lessons.findById(assignment.lessonId)
            }
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment,
                lesson: lesson
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        }
    }

    const navigateToSubmission = async (student, assignment) => {
        const submissionsResponse = await assignmentsModel.getGrades({ assignmentId: assignment.id })
        const currentSubmission = student.submissions.find(s => s.studentClassAssignmentId === assignment.id)
        const currentIndex = submissionsResponse.data.findIndex(res => res.submission.id === currentSubmission.id)

        navigate("/dashboard/teacher/assignments/grading/submission", {
            state: {
                submissions: submissionsResponse.data,
                assignment: assignment,
                currentIndex
            }
        })
    }

    const onClickAddAssignments = () => {
        // reset selectedGradeInit and initStandardId filters so that grade level and standard id from current class would be pre-selected in lesson search page
        localStorage.removeItem("selectedGrades")
        localStorage.removeItem("selectedStandardId")
        navigate("/dashboard/teacher/assignments/search")
    }

    return (
        <>
            {assignments.length !== 0 ? <Table responsive className='mb-5 table-striped' style={{ verticalAlign: "middle" }}>
                <thead style={{ verticalAlign: "middle" }}>
                    <tr>
                        <th className="border-end"></th>
                        {assignments.map(assignment => (
                            <th
                                key={assignment.id}
                                className="text-center align-middle border-end"
                                onClick={() => handleAssignmentClick(assignment)}
                                role="button"
                            >
                                <div>
                                    <p className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>
                                        {assignment.title}
                                    </p>
                                </div>
                            </th>
                        ))}
                        <th className="text-center py-3">Student Class Grade</th>
                    </tr>
                    <tr className='border-bottom'>
                        <td className='text-center py-3 px-4' style={{ fontWeight: "bold" }}>Due Date</td>
                        {assignments.map(assignment => (
                            <td key={assignment.id} className="text-center py-auto">
                                {assignment.dueDate ? formatToNumericalDate(assignment.dueDate) : "N/A"}
                            </td>
                        ))}
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ verticalAlign: "middle" }}>
                    {students.map(student => (
                        <tr key={student.uuid}>
                            <ToolTip title="Student Records">
                                <td
                                    className={`${styles.studentEntry} text-center py-3 border-end`}
                                    role="button"
                                    onClick={() => {
                                        window.location.href = `/student/records/${encodeURIComponent(student.uuid)}/${encodeURIComponent(student.name)}`
                                    }}
                                >
                                    {student.name}
                                </td>
                            </ToolTip>
                            {assignments.map(assignment => {
                                const [assignemntStatus, studentGrade] = formatStudentAssignmentGrade(student, assignment)
                                if (assignemntStatus === "Late") {
                                    return (
                                        <ToolTip title={assignemntStatus === "Late" && "Late assignment"}>
                                            <td key={assignment.id} style={{ backgroundColor: "#fec1c1" }} className={`text-center py-3`}>
                                                {studentGrade}
                                            </td>
                                        </ToolTip>
                                    )
                                } else {
                                    return (
                                        <td key={assignment.id} className={`${assignemntStatus === "Submitted" && styles.submission} text-center py-3`} onClick={assignemntStatus === "Submitted" ? () => navigateToSubmission(student, assignment) : null}>
                                            {studentGrade}
                                        </td>
                                    )
                                }
                            })}
                            <td className="text-center py-3" style={{ fontWeight: "bold" }}>{calculateStudentGrade(student)}</td>
                        </tr>
                    ))}
                    <tr className='border-top'>
                        <td className="text-center py-3 px-4" style={{ fontWeight: "bold" }}>Class Average</td>
                        {assignments.map(assignment => (
                            <td key={assignment.id} className="text-center" style={{ fontWeight: "bold" }}>
                                {calculateClassAverage(assignment)}
                            </td>
                        ))}
                        <td></td>
                    </tr>
                </tbody>
            </Table>
                :
                <EmptyTablePreview
                    title="Create Assignments"
                    description="When you add an assignment, it will be displayed at the top of the gradebook."
                >
                    <Button
                        variant="theme"
                        onClick={onClickAddAssignments}
                        data-testid="empty-add-assignment"
                    >
                        + Add Assignments
                    </Button>
                </EmptyTablePreview>

            }
        </>
    )
}
