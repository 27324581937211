import { Col, Card } from "react-bootstrap"
import { CurriculumBook } from "shared/types/curriculumTypes"
import styles from "./Curriculum.module.scss";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLElement> {
    textbook: CurriculumBook
}
export default function TextbookItem({ textbook, ...props }: Props) {

    return (
        <Col md={4}>
            <Card>
                <Card.Body {...props}  className={styles.textbookCardBody}>
                    {textbook.image ? (
                        <Card.Img variant="top" src={textbook.image} className="mb-3" />
                    ) : (
                        <Card.Img variant="top" src="/images/placeholder.png" className="mb-3" />
                    )}
                    <Card.Title>{textbook.name}</Card.Title>
                    <Card.Text>{textbook.description}</Card.Text>
                </Card.Body>
            </Card>
        </Col>
    )
}
