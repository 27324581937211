import * as React from "react"
import Skeleton from "react-loading-skeleton"
import ToolTip from "../../General/ToolTip"
import { ReactTable } from '@stem-sims/nexus'
import tableStyles from "scss/TeacherAssignments.module.scss"
import { AssignmentResponse } from "shared/types/teacherTypes"
import { formatToLongDate } from "helpers/dateHelper"
import styles from "../General/general.module.scss"

interface AssignmentsProps {
    assignments: any[]
    loading: boolean
    onRowClick: (row) => void
    changeBackgroundColor: (row) => string
    rowTooltip: string
    extraCols: any[]
    checkboxCol?: any
}

export default function Assignments({ assignments, loading, onRowClick, changeBackgroundColor, rowTooltip, extraCols, checkboxCol }: AssignmentsProps) {

    const dateStringSort = React.useMemo(
        () => (rowA, rowB) => {
            const dateAValue = rowA.values.dueDate;
            const dateBValue = rowB.values.dueDate;
            if (dateAValue === 'N/A' && dateBValue === 'N/A') return 0;
            if (dateAValue === 'N/A') return 1; 
            if (dateBValue === 'N/A') return -1; 
    
            const dateA = new Date(dateAValue);
            const dateB = new Date(dateBValue);
            const diff = dateA.getTime() - dateB.getTime();
    
            return diff > 0 ? 1 : diff < 0 ? -1 : 0;
        },
        []
    )

    const columns = [
        {
            Header: 'Title',
            minWidth: 225,
            accessor: 'title',
            Cell: ({ row }) => {
                const assignment: AssignmentResponse = row.original
                const topicType = !!assignment.topicId
                const lessonType = !!assignment.lessonId
                return <ToolTip title={rowTooltip}>
                    <div className="text-start">
                        <span className="me-2">{assignment.title}</span>
                        <span className={`rounded px-2 ${topicType ? styles.labelTagTopic : lessonType ? styles.labelTagLesson : styles.labelTagAssessment}`}>
                            {topicType ? "topic" : (lessonType ? "lesson" : "assessment")}
                        </span>
                    </div>
                </ToolTip>
            }
        },
        {
            Header: 'Module Name',
            id: 'lesson.module',
            accessor: (assignment) => assignment.moduleName ? assignment.moduleName : (assignment.lesson?.module ? assignment.lesson.module : 'N/A'),
            Cell: ({ row }) => {
                const assignment: AssignmentResponse = row.original
                return (<ToolTip title={rowTooltip}>
                    <div className="text-center">
                        {assignment.moduleName ?? 'N/A'}
                    </div>
                </ToolTip>)
            },
        },
        {
            Header: 'Lesson Number',
            id: 'lesson.number',
            accessor: (assignment) => assignment.lesson?.number,
            maxWidth: 90,
            Cell: ({ row }) => {
                return <ToolTip title={rowTooltip}>
                    <div>{row.original.lesson?.number ?? "N/A"}</div>
                </ToolTip>
            }
        },
        {
            Header: 'Due Date',
            accessor: (data) => data.dueDate ? formatToLongDate(data.dueDate) : 'N/A',
            id: 'dueDate',
            sortType: dateStringSort
        },      
    ]
    columns.push(...extraCols)
    if (checkboxCol) {
        columns.unshift(checkboxCol)
    }

    return (<>
        {loading ? <div className="mt-5">
            <Skeleton height={40} count={6} />
        </div>
        :
            <div className={`${tableStyles.reactTable} class-assignments`}>
                <ReactTable columns={columns} data={assignments} clickRow={onRowClick} customClass={changeBackgroundColor} sortBy={[{ id: 'title' }]} bulkActions={[]} />
            </div>
        }
    </>)
}
